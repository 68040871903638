import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";

import Logo from "../../assets/images/Logos/RSG_Logo_White.png";
import TwitterLogo from "../../assets/icons/TwitterX.png";
import LinkedInLogo from "../../assets/icons/LinkedInNewIcon.png";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__top">
        
        <div className="footer__logo-address-container">
          <div className="footer__logo-container">
            <img src={Logo} alt="RSG Logo" className="footer__logo" />
          </div>

          <div className="footer__address-container">
            <p className="footer__address">
              180 Ram Forest Road Whitchurch-Stouffville, ON
              <br></br>L4A 2G8
            </p>
          </div>
        </div>

        <div className="footer__links-icons-container">
          <div className="footer__links-container">
            <Link to="/about" className="footer__link">
              About Us
            </Link>
            <Link to="/media" className="footer__link">
              Media
            </Link>
            <Link to="/contact" className="footer__link">
              Contact
            </Link>
            <Link to="https://rsginternational.applytojob.com/apply" className="footer__link" target="_blank" rel="noopener noreferrer">
              Careers
            </Link>
          </div>

          <div className="footer__icons-container">
            <a href="https://twitter.com/RSG_Int" className="footer__icon-link">
              <img src={TwitterLogo} alt="Twitter Logo" className="footer__icon" />
            </a>
            <a href="https://www.linkedin.com/company/rsg-international-ca/?originalSubdomain=ca" className="footer__icon-link">
              <img src={LinkedInLogo} alt="LinkedIn Logo" className="footer__icon" />
            </a>
          </div>
        </div>
      </div>

      <div className="footer__bottom">
        <p className="footer__copyright">
          © 2025 RSG International. All Rights Reserved.
        </p>
        <div className="footer__terms">
        <Link to="/accessibility" className="footer__term-link">
            Accessibility
          </Link>
          <Link to="/whistleblowing" className="footer__term-link">
            Whistleblowing
          </Link>
          <Link to="/privacy-policy" className="footer__term-link">
            Privacy Policy
          </Link>
          <Link to="/terms-of-use" className="footer__term-link">
            Terms of Use
          </Link>
          
        </div>
      </div>
    </div>
  );
};

export default Footer;
